#locales-popper {
  z-index: 5;
  background-color: #00000003;
  backdrop-filter: blur(33px);
  -webkit-backdrop-filter: blur(33px);
  margin-top: 10px;
  border-radius: 10px;
  .MuiPaper-root.locales-menu {
    padding: 6px 6px;
    display: flex;
    flex-direction: column;
    width: 80px;
    max-width: 80px;
    button {
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 4px;
      &:not(:hover) {
        background-color: #00000003 !important;
      }
    }
  }
}
