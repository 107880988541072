.pill {
  border: 1px solid #8977f5;
  border-radius: 10px;
  margin-left: 10px;
  text-align: center;
  background-color: transparent;
  color: #8977f5;
  vertical-align: middle;
  width: 50px;
  height: 16px;
  padding: 1px;
  display: inline-block;
  font-size: 0.6rem;
  line-height: 0.8rem;
}
