@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.card-container {
  background-color: #0002;
  padding: 15px !important;
  border-radius: 10px;
  margin-bottom: 30px !important;

  .grid-item {
    padding: 5px !important;

    .MuiSkeleton-root {
      height: 1.2em;
      /* display: block; */
      background-color: rgb(227 227 227) !important;
    }

    .label-container {
      min-height: 50px;
      border-radius: 10px;
      margin: 0px;
      padding: 10px;
      background-color: rgba(71, 162, 247, 0.685);

      .label-arrange {
        display: flex;
        justify-content: space-between;
      }

      .label-title {
        font-size: 18px;

      }

      .label-value {
        display: flex;
        justify-content: center;
        font-size: 24px;
        margin: 10px;

      }
    }

    .progressbar-container {
      padding: 10px;
      margin: 10px;
      background-color: #0004;
      border-radius: 50%;

    }

    .claim-button {
      width: 100%;
      height: 100%;
      padding: 17px;
      font-size: 22px;
      color: white;
      border-style: none;
      border-radius: 10px;
      // border-color: #2d699e;
      /* background-color: red; */
      background: linear-gradient(90deg, #4786D6 37.02%, #2d689e 100%);
    }

    .claim-button:hover {
      // border-style: solid;
      background: #0495B7;
    }

    .claim-button:disabled {
      background: #0004 !important;
    }

  }

}


.title-small {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.15em;

  color: #0495B7;
}

.title-medium {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  letter-spacing: 0.105em;

  color: #FFFFFF;
}

.title-big {
  margin-top: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #545454;
}


#airdrop-view {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  min-width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }

  .ohm-card {
    max-width: unset !important;
    width: 100%;
    margin: auto;

    .card-header {
      margin-bottom: 1rem;
    }

    &.ohm-chart-card {
      .chart-card-header {
        padding: 0px 20px !important;
        min-width: 300px;
      }

      height: 355px;
    }
  }

  .hero-metrics {
    width: 100%;
    margin-bottom: 23px;
    text-align: center;

    .ohm-card {
      max-width: unset !important;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      h5 {
        font-weight: bold;
      }
    }
  }

  .data-grid {
    overflow: hidden;
  }

  .title-small {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.15em;

    color: #0495B7;
  }

  .title-medium {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */

    letter-spacing: 0.105em;

    color: #FFFFFF;
  }

  .title-big {
    margin-top: 80px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: -0.02em;

    color: #545454;
  }


  .airdrop-token-info-container {
    display: flex;
    justify-content: flex-start;
    min-height: 60px;
    min-width: 450px;
    margin-top: 20px;
    margin-bottom: 30px;

    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 22px;

    .item-title {
      font-family: 'Montserrat';
      align-self: center;
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 23px;
      letter-spacing: -0.02em;
      margin-left: 30px;
      color: #7F7F7F;
    }

    .item-value {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      align-self: center;
      margin-left: 10px;
      min-width: 60px;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      text-align: center;
      /* identical to box height */

      letter-spacing: -0.02em;

      color: #B8B8B8;

      .logo {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border-style: none;
        margin-left: 5px;

      }

    }

    .item-value-with-logo {
      display: flex;
      justify-content: center;
    }
  }

  .progressbar-container {
    padding: 0px;

    .MuiLinearProgress-root {
      height: 11px;
      border-radius: 65px;
      overflow: hidden;
      position: relative;
      background: #fff;
    }

    .MuiLinearProgress-barColorPrimary {
      border-radius: 48px;
      background: linear-gradient(90deg, #3f63ae 0%, #42DACE 100%);
    }
  }

  .airdrop {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 40px;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .item {
      padding: 32px;
      margin: 0 20px;
      display: flex;
      width: 280px;
      flex-direction: column;
      //      background-color: #000D56 !important;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
      background: #FFFFFF;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
      border-radius: 22px;
    }



    .network-swtich-button-container {
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button {
      z-index: 0;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin: 10px;
    }

    .label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #6D6D6D;
      padding: 5px;
    }

    .receive-token {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 130%;
      color: #999;
      padding: 5px;
    }

    .network-name {
      font-family: 'Montserrat';
      font-style: bold;
      font-weight: 900;
      font-size: 37px;
      line-height: 45px;
      letter-spacing: 0.005em;
      color: #43B1F9;
      margin: 10px;
      padding: 5px;
      padding-left: 0px;
      margin-left: 10px;
    }
  }

  .swap-button-container {
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 5px;
    margin-top: 30px;

    .button {
      padding: 25px;
      padding-bottom: 30px;
      width: 200px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: white;
      background: linear-gradient(90deg, #FF2AAA -3.88%, #E072EA 100%);
      box-shadow: 0px 1px 8px rgb(255 255 255 / 80%) !important;
      border-radius: 50px !important;
    }

    .button:hover {
      background: rgba(255, 255, 255, 0.0);
    }
  }



}

.smaller {
  .ohm-card {
    width: 97%;
  }

  .hero-metrics .ohm-card {
    >.MuiBox-root {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    div.metric {
      text-align: left !important;
    }
  }
}

.mobile {
  div.metric {
    white-space: nowrap;
    min-width: 30% !important;
    max-width: 60%;
    text-align: left !important;

    &.market,
    &.price,
    &.wsoprice,
    &.circ,
    &.bpo,
    &.index {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.very-small {
  .hero-metrics .ohm-card {
    >.MuiBox-root {
      flex-direction: column;
      justify-content: center;
    }
  }
}

.header-title {
  font-family: 'Montserrat';
  background-color: #FFFFFF;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-border {
  border: solid 1px #c59c20;
  width: 90%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 30px;
  background-color: #000D56 !important;
  opacity: 0.9;
  margin-bottom: 20px;
}

.MuiDialog-paper {
  background: #4e84f4 !important;
}

.MuiCircularProgress-root {
  width: 35px !important;
  height: 35px !important;
}

.MuiCircularProgress-colorSecondary {
  color: #4e84f4 !important;
}

.page-container {
  display: flex;
  justify-content: space-between;
}